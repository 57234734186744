import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

export const fetchUser = createAsyncThunk('user/fetchUser', async () => {
  const response = await axios.get('/api/status')
  return response.data
})

export const loginWithEmail = createAsyncThunk('user/loginWithEmail', async ({ email, password, isLogin }) => {
  const endpoint = isLogin ? '/api/auth-user/login-email' : '/api/auth-user/register'
  try {
    const response = await axios.post(endpoint, {
      email,
      password
    })
    return response.data
  } catch (error) {
    throw new Error(error.response?.data?.error || 'Authentication failed')
  }
})

export const loginUser = createAsyncThunk('user/loginUser', async (loginCode) => {
  if (!loginCode || loginCode === '') {
    throw new Error('Login code is required')
  }

  try {
    const response = await axios.post('/api/login', {
      login_code: loginCode,
    })
    return response.data
  } catch (error) {
    return error.response.data
    // console.error('Error logging in:', error)
    // throw new Error('Error logging in')
  }
})

export const fetchSocials = createAsyncThunk('user/fetchSocials', async () => {
  const response = await axios.get('/api/socials')
  return response.data.socials
})

export const logoutUser = createAsyncThunk('user/logoutUser', async () => {
  await axios.post('/api/logout')
})

export const fetchTokens = createAsyncThunk('user/fetchTokens', async () => {
  const response = await axios.get('/api/tokens')
  return response.data.tokens
})

const initialUserState = {
  user: {},
  socials: [],
  tokens: [],
  status: 'idle',
  error: null,
  loginError: null,
  userFetched: false,
  haveTokens: {},
  hasLoggedIn: false,
  loginSuccess: null,
}

const userSlice = createSlice({
  name: 'user',
  initialState: {
    ...initialUserState,
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload
    },
    resetUser: (state, action) => {
      return {
        ...initialUserState,
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUser.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.user = action.payload.user
        state.haveTokens = action.payload.haveTokens
        state.userFetched = true
      })
      .addCase(fetchUser.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })
      .addCase(loginUser.pending, (state) => {
        state.hasLoggedIn = false
      })
      .addCase(loginUser.fulfilled, (state, { payload }) => {
        state.loginError = payload.error
        state.hasLoggedIn = true
        state.loginSuccess = payload.authed
      })
      .addCase(loginWithEmail.pending, (state) => {
        state.loginError = null
      })
      .addCase(loginWithEmail.fulfilled, (state, { payload }) => {
        state.user = payload.user || {}
        state.hasLoggedIn = payload.authed === true
        state.loginError = null
      })
      .addCase(loginWithEmail.rejected, (state, action) => {
        state.loginError = action.error.message
      })
      .addCase(fetchSocials.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.socials = action.payload
      })
      .addCase(fetchTokens.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.tokens = action.payload
      })
    // .addCase(loginUser.rejected, (state, action) => {
    //   console.log(action)
    //   state.loginError = action.error?.message
    // })
  },
})

export const { setUser, resetUser } = userSlice.actions
export default userSlice.reducer
