import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { fetchUser, loginUser, resetUser } from './reducers/userSlice'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'

function LoginPage() {
  const { loginCode } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const user = useSelector((state) => state.user.user)
  const userFetched = useSelector((state) => state.user.userFetched)
  const loginError = useSelector((state) => state.user.loginError)
  const hasLoggedIn = useSelector((state) => state.user.hasLoggedIn)
  const [authed, setAuthed] = useState(null)

  useEffect(() => {
    // user is not logged in or even trying to login (no code)
    if (_.isEmpty(loginCode) && userFetched && _.isEmpty(user.influencer_id)) {
      navigate('/contact-us')
    }
  }, [userFetched, user.influencer_id])

  useEffect(() => {
    const login = async () => {
      try {
        const loginResponse = await dispatch(loginUser(loginCode)).unwrap()
        const userResponse = await dispatch(fetchUser()).unwrap()
        console.log('Fetched user:', userResponse)

        // setAuthed(userResponse?.user?.authed)
      } catch (err) {
        console.error('Failed to login or fetch user:', err)
      }
    }

    dispatch(resetUser())

    if (loginCode) {
      login()
    }
  }, [loginCode])

  useEffect(() => {
    // if user authed, navigate to home page
    if (user.authed && hasLoggedIn) {
      // do this after a delay
      setTimeout(() => {
        navigate('/')
      }, 600)
    }
  }, [user.authed, hasLoggedIn])

  if (authed === false) {
    return (
      <div className="container mt-5">
        <div className="row justify-content-center">
          <div className="col-md-8">
            <div className="card bg-white">
              <div className="card-body">
                <div className="text-center">
                  <h2>Failed to login</h2>
                  <p>There was an error logging in. Please try again.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-8">
          <div className="card bg-white">
            <div className="card-body">
              {user.authed ? (
                <div className="text-center">Welcome...</div>
              ) : (
                <div className="text-center">
                  {loginError ? (
                    <div className="alert alert-danger" role="alert">
                      <p>{loginError}</p>
                    </div>
                  ) : (
                    <>
                      <h2>Waiting for login...</h2>
                      <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LoginPage
