import { useState, useEffect } from 'react'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { fetchYoutubeChannels } from './reducers/connectionsSlice'
import { handleConnect } from './utils'
import BackArrow from './BackArrow'
import { Link } from 'react-router-dom'
import millify from 'millify'
import fallbackImage from '/fallback.png'

function YoutubeChannels() {
  const dispatch = useDispatch()
  const channels = useSelector((state) => state.connections.youtube.channels)
  const token_id = useSelector((state) => state.connections.youtube.token_id)

  const channelsFetched = useSelector((state) => state.connections.youtube.fetched)

  const pending_social_id = localStorage.getItem('pending_social_id')

  const [channelConfirmed, setChannelConfirmed] = useState(false)

  const confirmChannel = async (channel) => {
    try {
      await axios.post('/api/confirm-youtube-channel', {
        channel_id: channel.id,
        social_account_id: pending_social_id,
        token_id,
      })

      setChannelConfirmed(true)
    } catch (error) {
      console.error('Error confirming the channel:', error)
    }
  }

  useEffect(() => {
    dispatch(
      fetchYoutubeChannels({
        social_account_id: pending_social_id,
      }),
    )
  }, [dispatch])

  // console.log(channels)

  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-8">
          <div className="card bg-white">
            <div className="card-body">
              {channelConfirmed ? (
                <>
                  <p
                    className="card-title text-center mb-4 fw-bold"
                    style={{ fontSize: '1.5rem' }}
                  >
                    Thank You!
                  </p>
                  <p className="text-center">
                    Your YouTube channel has been successfully confirmed.
                  </p>
                  <p className="text-center">
                    <Link to="/">Main Page</Link>
                  </p>
                </>
              ) : (
                <>
                  <p
                    style={{
                      fontSize: '1.5rem',
                    }}
                    className="card-title text-center mb-4 fw-bold"
                  >
                    Almost there!
                  </p>
                  <p>
                    Below you will see the channel or channels we found for the account you just
                    connected. We will display media from your YouTube channel on your Talent Pitch
                    Pro profile, along with account insights related to your audience demographics
                    and engagement.
                  </p>
                  <p>
                    To make sure we have the right channel for you, click to confirm the channel
                    you want to connect to your Talent Pitch Pro profile.
                  </p>
                  <hr />
                  {channels.length > 0 ? (
                    <>
                      {channels.map((channel, i) => {
                        const subscribers = channel.statistics?.subscriberCount
                          ? `(${millify(channel.statistics.subscriberCount)})`
                          : ''

                        return (
                          <div key={channel.id} className="mb-3">
                            <div className="row g-0">
                              <div className="col-md-4 d-flex justify-content-center align-items-center text-center">
                                <div className="d-flex flex-column align-items-center">
                                  <div className="w-50">
                                    <img
                                      src={channel.snippet.thumbnails.medium.url}
                                      className="img-fluid rounded-start"
                                      alt="channel"
                                      onError={(e) => {
                                        e.target.onerror = null // prevents infinite loop
                                        e.target.src = fallbackImage
                                      }}
                                    />
                                  </div>
                                  <div className="w-50 mt-2">
                                    <button
                                      onClick={() => confirmChannel(channel)}
                                      className="custom-confirm-button"
                                    >
                                      Confirm Channel
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-8">
                                <div className="card-body">
                                  <h5 className="card-title">
                                    {channel.snippet.title} {subscribers}
                                  </h5>
                                  <p className="card-text">{channel.snippet.description}</p>
                                </div>
                              </div>
                            </div>
                            <hr />
                          </div>
                        )
                      })}
                    </>
                  ) : (
                    <>
                      {channelsFetched && (
                        <>
                          <p>
                            It seems we couldn't find any YouTube channels connected to your
                            account. Are you sure you connected the right account? If you connected
                            the wrong account by accident, you can return to the main page, remove
                            the connection you just added (by selecting the same YouTube account
                            again), and retry.
                          </p>
                          <p>
                            We will be looking into the issue to see if we can determine the cause
                            of the problem. If you have any questions, please{' '}
                            <a href="mailto:erick@talentpitchpro.com">contact us</a>.
                          </p>
                        </>
                      )}
                    </>
                  )}
                  <p className="text-center">
                    <Link to="/">Home</Link>
                  </p>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default YoutubeChannels
